import { useState, useEffect } from 'react'
import isDefined from 'utils/is-defined'
import isValidDate from 'utils/dates/is-valid-date'

export const useBetweenDates = ({ start, end }) => {
  const [valid, setValid] = useState()

  useEffect(() => {
    const todayDateTime = new Date().getTime()

    if (isDefined(start)) {
      const startDateTime = isValidDate(start) ? start.getTime() : new Date(start).getTime()
      if (isNaN(startDateTime) || startDateTime >= todayDateTime) {
        setValid(false)
        return
      }
    }

    if (isDefined(end)) {
      const endDateTime = isValidDate(end) ? end.getTime() : new Date(end).getTime()
      if (isNaN(endDateTime) || endDateTime <= todayDateTime) {
        setValid(false)
        return
      }
    }

    setValid(true)
  }, [start, end])

  return valid
}
