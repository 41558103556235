import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H2 from 'components/new/typography/h2'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import AdditionalResources from 'components/new/additional-resources'
// import Checklist from 'components/new/checklist'
import Spacer from 'components/new/spacer'
// import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { useBetweenDates } from 'hooks/use-between-dates'
// import { RegionPickerModal, SelectedRegionRibbon, useRegionPicker } from 'components/region-picker'
// import { LoadingScreen, useLoadingScreen } from 'components/loading-screen'

const baseUrl = '/service/inspections'

const inspectionPrograms = [
  {
    imageId: 'tractorsImage',
    title: '5-9 Series Tractors',
    handle: 'tractors',
  },
  {
    imageId: 'sprayersImage',
    title: 'Sprayers',
    handle: 'sprayers',
  },
  {
    imageId: 'combinesImage',
    title: 'Combines',
    handle: 'combines',
  },
  {
    imageId: 'draperPlatformsImage',
    title: 'Drapers Platforms',
    handle: 'draper-platforms',
  },
  {
    imageId: 'headersImage',
    title: 'Corn Heads',
    handle: 'corn-heads',
  },
  {
    imageId: 'plantersImage',
    title: 'Planters',
    handle: 'planters',
  },
  {
    imageId: 'airDrillsImage',
    title: 'Air Drills',
    handle: 'air-drills',
  },
  {
    imageId: 'balersImage',
    title: 'Balers',
    handle: 'balers',
  },
  {
    imageId: 'mocosImage',
    title: 'Mower Conditioners',
    handle: 'mower-conditioners',
  },
  {
    imageId: 'forageHarvestersImage',
    title: 'Self-Propelled Forage Harvesters',
    handle: 'self-propelled-forage-harvesters',
  },
  {
    imageId: 'spfhCornHeadsImage',
    title: 'SPFH Corn Heads',
    handle: 'spfh-corn-heads',
  },
  {
    imageId: 'spfhHayHeadsImage',
    title: 'SPFH Hay Heads',
    handle: 'spfh-hay-heads',
  },
]

const InspectionsPage = props => {
  const {
    data: {
      heroImage,
      // multiUnitInspectionImage
    },
  } = props
  const [inspectionProgramsData] = useState(() => {
    return inspectionPrograms.map(program => {
      return {
        ...program,
        image: props.data[program.imageId],
      }
    })
  })
  const promotionActive = useBetweenDates({ end: new Date('2025-07-01T00:00:00-06:00') })
  // const {
  //   region,
  //   setRegion,
  //   isOpen: regionPickerModalOpen,
  //   toggleModal: toggleRegionPickerModal,
  // } = useRegionPicker()
  // const { loading } = useLoadingScreen({ conditions: [region] })
  return (
    <Layout>
      <Helmet>
        <title>Equipment Inspections | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Equipment Inspections'
        overlayOpacity={0.3}
      />

      {/* {promotionActive ? (
        <React.Fragment>
          <RegionPickerModal
            isOpen={regionPickerModalOpen}
            region={region}
            setRegion={setRegion}
            toggleModal={toggleRegionPickerModal}
          />

          <LoadingScreen
            loading={loading}
            message='Fetching inspection program for your region...'
          />
        </React.Fragment>
      ) : null} */}

      <div /*style={{ display: promotionActive && loading ? 'none' : 'block' }}*/>
        {/* {promotionActive ? (
          <SelectedRegionRibbon
            style={{ backgroundColor: '#eaebeb' }}
            region={region}
            toggleModal={toggleRegionPickerModal}
          />
        ) : null} */}
        <Content kind='full'>
          <H2>Importance of Inspections</H2>
          <P>
            When you're running in a tight window during planting or harvest, the last thing you
            want is to be down a machine. Let our certified technicians inspect and repair your
            equipment so we can fix any potential problems while it's not being used.
          </P>
          <Spacer size='xl' variable />
          <H2 id='parts-warranty'>12-Month Parts Warranty</H2>
          <P>
            Support from our service team doesn't stop at the garage door. When we install new or
            remanufactured John Deere, A&I and Sunbelt parts, we cover them for a full year.
          </P>
          {/* {!promotionActive ? (
            <React.Fragment>
              <Spacer size='m' variable />
              <ButtonContainer>
                <Button as='a' href='#programs' ghost color='green'>
                  View Inspection Programs
                </Button>
              </ButtonContainer>
            </React.Fragment>
          ) : null}
          <Spacer size='xl' variable /> */}
        </Content>
        {promotionActive ? (
          <div style={{ height: '70vh', minHeight: '250px' }}>
            <iframe
              src='https://player.flipsnack.com?hash=NTk3NUFCREQ3NUUrdWs1eWs5cGQxag=='
              title='2024-2025 Service & Inspections Guide'
              width='100%'
              height='100%'
              seamless='seamless'
              scrolling='no'
              frameBorder='0'
              allowFullScreen
              allow='autoplay; clipboard-read; clipboard-write'
            ></iframe>
          </div>
        ) : null}
        {/* <LightSection>
          <Content kind='full' id='multi-unit-inspections'>
            <OneHalfGrid>
              <OneHalfColumn>
                <H2>Multi-Unit Inspections</H2>
                <P>
                  Have multiple units that need to be inspected? Schedule a multi-unit inspection
                  with your local service manager to qualify for additional discounts!
                </P>
                <StyledChecklist>
                  <P>Preferred Multi-Unit Inspection discount pricing</P>
                  <P>Flexible payment options may be available</P>
                  <P>Hutson-installed parts are covered for 12 months</P>
                </StyledChecklist>
                <Spacer size='s' />
                <H4 as='p'>Ask about multi-unit inspection programs when you schedule!</H4>
              </OneHalfColumn>
              <OneHalfColumn>
                <GatsbyImage
                  image={multiUnitInspectionImage.childImageSharp.gatsbyImageData}
                  alt='John Deere tractor, combine, and sprayer'
                />
              </OneHalfColumn>
            </OneHalfGrid>
          </Content>
        </LightSection> */}
        <Content kind='full'>
          <H2 id='programs'>Inspection Programs</H2>
          <Spacer size='m' />
          <InspectionProgramsGrid>
            {inspectionProgramsData.map(program => (
              <InspectionProgramColumn key={program.imageId}>
                <ProgramLink to={`${baseUrl}/${program.handle}/`}>
                  <GatsbyImage
                    image={program.image.childImageSharp.gatsbyImageData}
                    alt={program.title}
                  />
                  <Spacer size='xs' />
                  <ProgramTitle>{program.title}</ProgramTitle>
                  <Spacer size='xs' />
                  <DetailsLinkText>View Details ›</DetailsLinkText>
                </ProgramLink>
              </InspectionProgramColumn>
            ))}
          </InspectionProgramsGrid>
        </Content>
        <AdditionalResources
          resources={[
            {
              title: 'Parts & Service',
              links: [
                <Link to='/parts/'>Parts at Hutson</Link>,
                <Link to='/service/'>Service at Hutson</Link>,
                <Link to='/hutson-customer-portal/'>Hutson Customer Portal</Link>,
              ],
            },
            {
              title: 'Offers & Discounts',
              links: [
                <OutboundLink href='https://www.deere.com/en/finance/financing/multi-use-account/'>
                  John Deere Multi-Use Account
                </OutboundLink>,
                <Link to='/promotions/service/'>Service Promotions</Link>,
              ],
            },
            {
              title: 'Warranties',
              links: [<Link to='/powergard/'>PowerGard Protection Plan</Link>],
            },
          ]}
        />
      </div>
    </Layout>
  )
}

// const LightSection = styled.div`
//   background-color: ${props => props.theme.color.n20};
// `

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

// const StyledChecklist = styled(Checklist)`
//   margin: 0;
//   padding: 0;

//   p {
//     margin: 0;
//     line-height: inherit;
//   }
// `

// const ButtonContainer = styled.div`
//   a {
//     display: inline-block;
//     text-decoration: none;
//   }
// `

// const OneHalfGrid = styled.div`
//   @media (min-width: 900px) {
//     align-items: flex-end;
//     display: flex;
//     flex-direction: row;
//     ${clearfix}
//   }
// `

// const OneHalfColumn = styled.div`
//   @media (max-width: 899px) {
//     :not(:last-child) {
//       margin-bottom: ${props => props.theme.size.l};
//     }
//   }

//   @media (min-width: 900px) {
//     ${column('1/2')}
//   }
// `

const InspectionProgramsGrid = styled.div`
  @media (min-width: 1px) {
    ${clearfix}
  }
`

const InspectionProgramColumn = styled.div`
  :not(:last-child) {
    margin-bottom: ${props => props.theme.size.m};
  }

  @media (min-width: 400px) {
    ${column('1/2')}
  }

  @media (min-width: 600px) {
    ${column('1/3')}
  }

  @media (min-width: 900px) {
    ${column('1/4')}
  }

  @media (min-width: 1100px) {
    ${column('1/5')}
  }
`

const ProgramTitle = styled(H4)`
  color: ${props => props.theme.color.n900};
  text-align: center;
`

const DetailsLinkText = styled.span`
  color: ${props => props.theme.color.g400};
  display: block;
  text-align: center;
`

const ProgramLink = styled(Link)`
  text-decoration: none;

  :hover,
  :focus {
    ${DetailsLinkText} {
      text-decoration: underline;
    }
  }
`

export const pageQuery = graphql`
  query inspectionsPageQuery {
    heroImage: file(relativePath: { eq: "service/inspections/inspections-hero.jpg" }) {
      ...FullWidthImage
    }
    # multiUnitInspectionImage: file(
    #   relativePath: { eq: "service/inspections/multi-unit-inspection.png" }
    # ) {
    #   ...SharpImage800
    # }
    tractorsImage: file(relativePath: { eq: "service/inspections/tractors.jpg" }) {
      ...SharpImage480
    }
    combinesImage: file(relativePath: { eq: "service/inspections/combines.jpg" }) {
      ...SharpImage480
    }
    sprayersImage: file(relativePath: { eq: "service/inspections/sprayers.jpg" }) {
      ...SharpImage480
    }
    plantersImage: file(relativePath: { eq: "service/inspections/planters.jpg" }) {
      ...SharpImage480
    }
    airDrillsImage: file(relativePath: { eq: "service/inspections/air-drills.jpg" }) {
      ...SharpImage480
    }
    balersImage: file(relativePath: { eq: "service/inspections/balers.jpg" }) {
      ...SharpImage480
    }
    draperPlatformsImage: file(relativePath: { eq: "service/inspections/draper-platforms.jpg" }) {
      ...SharpImage480
    }
    headersImage: file(relativePath: { eq: "service/inspections/corn-heads.jpg" }) {
      ...SharpImage480
    }
    mocosImage: file(relativePath: { eq: "service/inspections/mower-conditioners.jpg" }) {
      ...SharpImage480
    }
    forageHarvestersImage: file(relativePath: { eq: "service/inspections/forage-harvesters.jpg" }) {
      ...SharpImage480
    }
    spfhCornHeadsImage: file(relativePath: { eq: "service/inspections/spfh-corn-heads.jpg" }) {
      ...SharpImage480
    }
    spfhHayHeadsImage: file(relativePath: { eq: "service/inspections/spfh-hay-heads.jpg" }) {
      ...SharpImage480
    }
  }
`

export default InspectionsPage
